exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policies-privacy-mdx": () => import("./../../../src/pages/policies/privacy.mdx" /* webpackChunkName: "component---src-pages-policies-privacy-mdx" */),
  "component---src-pages-policies-terms-mdx": () => import("./../../../src/pages/policies/terms.mdx" /* webpackChunkName: "component---src-pages-policies-terms-mdx" */),
  "component---src-stories-co-2-index-tsx": () => import("./../../../src/stories/co2/index.tsx" /* webpackChunkName: "component---src-stories-co-2-index-tsx" */),
  "component---src-stories-ner-index-tsx": () => import("./../../../src/stories/ner/index.tsx" /* webpackChunkName: "component---src-stories-ner-index-tsx" */),
  "component---src-templates-story-group-tsx": () => import("./../../../src/templates/storyGroup.tsx" /* webpackChunkName: "component---src-templates-story-group-tsx" */)
}

